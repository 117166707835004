@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'TAN-PEARL';
  src: url('./fonts/TAN-PEARL.ttf') format('truetype');
}

body {
  overflow: overlay;
  font-family: "TAN-PEARL", sans-serif; /* Corrected the font-family name */
  font-optical-sizing: auto;
  font-style: normal;
}